import React from 'react'
import ProCommon from './ProCommon'

const ProBlog = () => {
    return (
        <>
            <div className="profile_container">
                <ProCommon />
            </div>
            <h2>Blog</h2>
        </>
    )
}

export default ProBlog