export const REGISTER_REQUEST="REGISTER_REQUEST"
export const REGISTER_SUCCESS="REGISTER_SUCCESS"
export const REGISTER_FAIL="REGISTER_FAIL"

export const LOGIN_REQUEST="LOGIN_REQUEST"
export const LOGIN_SUCCESS="LOGIN_SUCCESS"
export const LOGIN_FAIL="LOGIN_FAIL"

export const ALL_USERS_REQUEST="ALL_USERS_REQUEST"
export const ALL_USERS_SUCCESS="ALL_USERS_SUCCESS"
export const ALL_USERS_FAIL="ALL_USERS_FAIL"

export const UPDATE_PROFILE_REQUEST="UPDATE_PROFILE_REQUEST"
export const UPDATE_PROFILE_SUCCESS="ALL_USERS_SUCCESS"
export const UPDATE_PROFILE_FAIL="UPDATE_PROFILE_FAIL"


export const UPDATE_PROFILE_RESET="UPDATE_PROFILE_RESET"
export const UPDATE_PASSWORD_RESET="UPDATE_PASSWORD_RESET"





export const LOAD_USER_REQUEST="LOAD_USER_REQUEST"
export const LOAD_USER_SUCCESS="LOAD_USER_SUCCESS"
export const LOAD_USER_FAIL="LOAD_USER_FAIL"


export const DELETE_USER_REQUEST="DELETE_USER_REQUEST"
export const DELETE_USER_SUCCESS="DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL="DELETE_USER_FAIL"

export const LOGOUT_REQUEST="LOGOUT_REQUEST"
export const LOGOUT_SUCCESS="LOGOUT_SUCCESS"
export const LOGOUT_FAIL="LOGOUT_FAIL"

export const FORGOT_PASSWORD_REQUEST="FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS="FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAIL="FORGOT_PASSWORD_FAIL"

export const RESET_PASSWORD_REQUEST="RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS="RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL="RESET_PASSWORD_FAIL"

export const UPDATE_PASSWORD_REQUEST="UPDATE_PASSWORD_REQUEST"
export const UPDATE_PASSWORD_SUCCESS="UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_FAIL="UPDATE_PASSWORD_FAIL"

export const CONTACT_REQUEST="CONTACT_REQUEST"
export const CONTACT_SUCCESS="CONTACT_SUCCESS"
export const CONTACT_FAIL="CONTACT_FAIL"



export const CLEAR_ERROR="CLEAR_ERROR"