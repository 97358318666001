import React from 'react'
import ProCommon from './ProCommon'

const ProAbout = () => {
    return (
        <>
            <div className="profile_container">
                <ProCommon />
            </div>
            <h2>About</h2>
        </>
    )
}

export default ProAbout