
export const ALL_POST_REQUEST="ALL_POST_REQUEST"
export const ALL_POST_SUCCESS="ALL_POST_SUCCESS"
export const ALL_POST_FAIL="ALL_POST_FAIL"

export const POST_DETAILS_REQUEST="POST_DETAILS_REQUEST"
export const POST_DETAILS_SUCCESS="POST_DETAILS_SUCCESS"
export const POST_DETAILS_FAIL="POST_DETAILS_FAIL"

export const DELETE_POST_REQUEST="DELETE_POST_REQUEST"
export const DELETE_POST_SUCCESS="DELETE_POST_SUCCESS"
export const DELETE_POST_FAIL="DELETE_POST_FAIL"

export const ADD_POST_REQUEST="ADD_POST_REQUEST"
export const ADD_POST_SUCCESS="ADD_POST_SUCCESS"
export const ADD_POST_FAIL="ADD_POST_FAIL"


export const CLEAR_ERROR="CLEAR_ERROR"